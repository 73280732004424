import React from 'react'
import ParagraphDark from '../ParagraphDark/ParagraphDark';

const ImageBannerWithContent = (props) => {


  return (
    <div
      className={`banner banner-Image ${props.customClass}`}
      style={{
        backgroundImage: `url(${props.bannerImage})`,
      }}
      data-aos={props.imgAnimationType}
      data-aos-delay={props.imgAnimationDuration}
    >
      <div className="box-1 GradientBlueLtoR">
          <p data-aos={props.titleAnimationType} data-aos-delay={props.titleAnimationType} className='bannerHeading'>{props.heading}</p>
       
          <ParagraphDark para={props.subHeading} customClass="" htmlPara='true'
            textColor={props.textColor}
          />
        
      </div>
      <div className="box-2 GradientBlueRtoL">
       
      </div>
    </div>
  )
}

export default ImageBannerWithContent